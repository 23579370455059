import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="container">
      <p className="copyright">Copyright &copy; 2023 Mom's Spice</p>
      <div className="social-links">
        <a href="https://www.facebook.com/momsspicerestaurant/"><i className="fab fa-facebook-f"></i></a>
        <a href="https://www.instagram.com/themomsspice/"><i className="fab fa-instagram"></i></a>
        <a href="https://twitter.com/TheMomsSpice"><i className="fab fa-twitter"></i></a>
      </div>
    </footer>
  );
};

export default Footer;
