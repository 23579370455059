import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'skeleton-css/css/skeleton.css';
import Header from './components/Header';
import Menu from './components/Menu';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
    return (
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/Footer" element={<Footer />} />
        </Routes>
        <Footer />
      </div>
    );
  };

export default App;
