import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section className="container">
      <h2 className="section-title">Contact Us</h2>
      <div className="row">
        <div className="six columns">
          <h3>Location</h3>
          <p>315 E Hurst Blvd, Hurst, TX 76053</p> {/* Updated location */}
          <h3>Hours</h3>
          <p>Friday-Thursday: 11 AM – 9 PM (Closed Sundays)</p> {/* Updated hours to match your business hours */}
        </div>
        <div className="six columns">
          <h3>Get in Touch</h3>
          {/* Commented out the form and added the phone number */}
          {/* <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" required />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" required></textarea>

            <button type="submit" className="button-primary">Send Message</button>
          </form> */}
          <p>Feel free to call us at: <a href="tel:+18173967553">(817) 396-7553</a></p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
