import React from 'react';
import './HomePage.css'; // Ensure this file exists and is correctly linked
import potLogo from '../potLogo.png'; // Update this path to where your actual logo image is stored
import BoilingText from './BoilingText'; // Make sure the path to BoilingText.js is correct


const HomePage = () => {
  return (
    <div className="container">
      <div className="row">
        <section className="banner twelve columns">
          <h1>Welcome to Mom's Spice</h1>
          <img src={potLogo} alt="Mom's Spice Img Logo" style={{ maxHeight: '230px' }} /> 
        </section>
      </div>

      <div className="row">
        <section className="about-us twelve columns">
        <BoilingText text="Mom's  Spice" />
<p>Discover the heart and soul of authentic Indian cuisine at Mom's Spice, a family-owned gem where traditional recipes meet fresh, local ingredients.</p>
          <p><strong>Address:</strong> 315 E Hurst Blvd, Hurst, TX 76053</p>
          <p><strong>Business Hours:</strong> Restaurant: Friday to Thursday: 11 AM – 9 PM (Closed Sundays) | Delivery & Takeout: Friday to Thursday: 11 AM – 7 PM (Closed Sundays)</p>
        </section>
      </div>

      <div className="row">
        <section className="cta twelve columns">
          <a href="tel:+18173967553" className="button">Call Now!</a>
        </section>
      </div>

      <footer className="home-footer row">
        <div className="twelve columns">
          <p>315 E Hurst Blvd, Hurst, TX 76053 | <a href="tel:+18173967553">(817) 396-7553</a></p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
