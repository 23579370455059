import React from 'react';
import './BoilingText.css'; // Make sure you link to the correct CSS file

const BoilingText = () => {
  const title = "Mom's Spice";

  return (
    <h2 className="boiling-text">
      {title.split('').map((char, index) => (
        char !== ' ' ? (
          <span key={index} className={`boiling-char char-${index}`}>
            {char}
          </span>
        ) : (
          // Render a non-breaking space for spaces
          <span key={index} aria-hidden="true">&nbsp;</span>
        )
      ))}
    </h2>
  );
};

export default BoilingText;
