import React from 'react';
import './Header.css';
// Assuming your logo image is stored in the public folder or within src/assets
import logoImage from '../textLogo.png'; // Update this path to where your actual logo image is stored

const Header = () => {
  return (
    <header className="container">
      <div className="row">
        <div className="three columns">
          <h1 className="logo">
            <a href="/">
              <img src={logoImage} alt="Mom's Spice Logo" style={{ maxHeight: '230px' }} /> {/* Adjust maxHeight as needed */}
            </a>
          </h1>
        </div>
        <div className="nine columns">
          <nav>
            <ul className="nav-links">
              <li><a href="/">Home</a></li>
              <li><a href="/menu">Menu</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
