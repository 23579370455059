import React from 'react';
import './Menu.css';

const Menu = () => {
  return (
    <section className="container">
      <h2 className="menu-section-title">Our Menu</h2>
      <div className="menu-row">
                {/* Section 4 */}
                <div className="menu-column">
        <h3 className="menu-section-name">Indian Grill</h3>
          <h5 className="menu-sub-section-name">Curry Lovers</h5>

          <h3 className="menu-item-name">CHICKEN</h3>
          <p className="menu-item-description">Experience the warmth of our Chicken Curry, made with tender chicken pieces simmered in a rich, aromatic curry sauce, infused with traditional spices.</p>
          {/* <p className="menu-item-price">$10.99</p> */}

          <h3 className="menu-item-name">BEEF</h3>
          <p className="menu-item-description">Delve into the depth of our Beef Curry, featuring slow-cooked beef that melts in your mouth, enveloped in an intensely flavorful curry sauce.</p>
          {/* <p className="menu-item-price">$13.99</p> */}

          <h4 className="menu-item-name">GOAT</h4>
          <p className="menu-item-description">Savor the unique taste of our Goat Curry, with succulent pieces of goat meat stewed in a spicy, aromatic sauce that captures the essence of Indian cuisine.</p>
          {/* <p className="menu-item-price">$16.99</p> */}

          <h3 className="menu-item-name">FISH</h3>
          <p className="menu-item-description">Dive into the rich flavors of our Fish Curry, featuring tender fish pieces cooked in a flavorful and aromatic curry sauce.</p>
          {/* <p className="menu-item-price">$14.99</p> */}
          

          <h5 className="menu-sub-section-name">BIRIYANI</h5>
          <h5 className="menu-subitem-name">CHICKEN</h5>
          <p className="menu-item-description">Indulge in our Chicken Biriyani, a fragrant rice dish layered with marinated chicken, cooked with exotic spices.</p>
          {/* <p className="menu-item-price">$8.99</p> */}
          
          <h5 className="menu-subitem-name">BEEF</h5>
          <p className="menu-item-description">Enjoy our Beef Biriyani, where perfectly cooked grains of rice embrace tender pieces of beef and a blend of traditional spices.</p>
          {/* <p className="menu-item-price">$10.99</p> */}

          <h5 className="menu-subitem-name">GOAT</h5>
          <p className="menu-item-description">Relish the rich flavors of our Goat Biriyani, a perfect mix of tender goat meat, basmati rice, and aromatic spices, cooked to perfection.</p>
          {/* <p className="menu-item-price">$12.99</p> */}

          <h5 className="menu-subitem-name">VEGETABLE</h5>
          <p className="menu-item-description">Savor our Vegetable Biriyani, a delightful array of fresh vegetables, fragrant rice, and aromatic herbs and spices, creating a flavorful feast.</p>
          {/* <p className="menu-item-price">$8.99</p> */}

          <h5 className="menu-subitem-name">FISH</h5>
          <p className="menu-item-description">Discover the delicate flavors of our Fish Biriyani, with succulent pieces of fish mingled with perfectly spiced rice, creating a memorable dish.</p>
          {/* <p className="menu-item-price">$14.99</p> */}

        </div>
        {/* Section 5 */}
        <div className="menu-column">
          <h5 className="menu-sub-section-name">Kebabs</h5>

  
          <h5 className="menu-subitem-name">CHICKEN SHISH (2) </h5>
          <p className="menu-item-description">Juicy, marinated chicken pieces skewered and grilled to perfection.</p>
          {/* <p className="menu-item-price">$10.99</p> */}

          <h5 className="menu-subitem-name">BEEF SHISH (2) </h5>
          <p className="menu-item-description">Flavorful beef pieces, marinated in exotic spices, skewered and grilled, offering a smoky and delicious taste.</p>
          {/* <p className="menu-item-price">$12.99</p> */}

          <h5 className="menu-subitem-name">CHICKEN ROLL </h5>
          <p className="menu-item-description">Tender grilled chicken wrapped in a fresh flatbread with greens and sauce, making a perfect, quick bite.</p>
          {/* <p className="menu-item-price">$7.99</p> */}

          <h5 className="menu-subitem-name">BEEF ROLL </h5>
          <p className="menu-item-description">Spicy grilled beef rolled in soft flatbread, complemented by fresh veggies and sauce, for a hearty meal.</p>
          {/* <p className="menu-item-price">$8.99</p> */}

          <h5 className="menu-sub-section-name">TANDOORI</h5>
          <h5 className="menu-subitem-name">TANDOORI CHICKEN</h5>
          <p className="menu-item-description">Enjoy the bold flavors of Tandoori Chicken, marinated in a blend of spices and cooked in a tandoor oven.</p>
          {/* <p className="menu-subitem-name">LEG QT. $3.99</p>
          <p className="menu-subitem-name">HALF $11.99</p>
          <p className="menu-subitem-name">FULL $20.99</p> */}
          <p></p>
          <h5 className="menu-subitem-name">CHICKEN BOTI</h5>
          <p className="menu-item-description">Succulent pieces of chicken marinated in spices and grilled in a tandoor, offering a smoky and spicy flavor.</p>
          {/* <p className="menu-item-price">$7.99</p> */}
        </div>

        <div className="menu-column">
          <h5 className="menu-sub-section-name">Sides</h5>
          <h4 className="menu-item-name">PULARO RICE</h4>
          <p className="menu-item-description">Aromatic basmati rice cooked with a hint of saffron and spices, making it the perfect accompaniment to our curries..</p>
          {/* <p className="menu-item-price">$5.99</p> */}

          <h4 className="menu-item-name">REGULAR NAAN</h4>
          <p className="menu-item-description">Soft and fluffy naan bread, baked in a tandoor, perfect for dipping into our rich and flavorful curries.</p>
          {/* <p className="menu-item-price">$1.99</p> */}

          <h4 className="menu-item-name">GARLIC NAAN</h4>
          <p className="menu-item-description">Naan bread infused with garlic and cilantro, baked to perfection, adding a fragrant touch to your meal.</p>
          {/* <p className="menu-item-price">$2.39</p> */}
          
          <h3 className="menu-item-name">MANGO LASSI</h3>
          <p className="menu-item-description">Quench your thirst with our Mango Lassi, a refreshing yogurt-based drink blended with ripe mangoes.</p>
          {/* <p className="menu-item-price">$4.99</p> */}


          <h5 className="menu-sub-section-name">Samosas (2)</h5>
          <h4 className="menu-item-name">VEG</h4>
          <p className="menu-item-description">Crispy pastry filled with a spicy mix of mashed potato and peas, served with tangy tamarind chutney.</p>
          {/* <p className="menu-item-price">$3.99</p> */}

          <h4 className="menu-item-name">CHICKEN</h4>
          <p className="menu-item-description">Delicious samosas stuffed with spicy chicken filling, a perfect snack or appetizer.</p>
          {/* <p className="menu-item-price">3.99</p> */}

          <h4 className="menu-item-name">BEEF</h4>
          <p className="menu-item-description">Hearty samosas packed with a flavorful beef filling, served with chutney for dipping.</p>
          {/* <p className="menu-item-price">$4.99</p> */}

        </div>



        {/* Section 6 */}
        <div className="menu-column">
        <h5 className="menu-sub-section-name">DAAL</h5>
          <h3 className="menu-item-name">CHANA DAAL</h3>
          <p className="menu-item-description">A comforting bowl of Chana Daal, made from split chickpeas, cooked to perfection with spices.</p>
          {/* <p className="menu-item-price">$7.99</p> */}

          <h3 className="menu-item-name">REGULAR DAAL</h3>
          <p className="menu-item-description">Our Regular Daal is a simple and nutritious dish made from lentils, cooked with traditional Indian spices.</p>
          {/* <p className="menu-item-price">$6.99</p> */}
        </div>

        {/* Section 7 */}
        <div className="menu-column">
          <h5 className="menu-sub-section-name">Masalas</h5>
          <h4 className="menu-item-name">PANEER TIKKA</h4>
          <p className="menu-item-description">Dive into the vibrant and creamy flavors of our Paneer Tikka Masala, featuring grilled paneer cubes enveloped in a rich, spicy tomato-based sauce.</p>
          {/* <p className="menu-item-price">$13.99</p> */}
          <h4 className="menu-item-name">CHICKEN TIKKA MASALA</h4>
          <p className="menu-item-description">Indulge in the rich and spicy flavors of our Chicken Tikka Masala. Tender chicken is marinated in zesty curry and served in a creamy sauce.</p>
          {/* <p className="menu-item-price">$13.99</p> */}
          <h4 className="menu-item-name">VEG TIKKA MASALA</h4>
          <p className="menu-item-description">Explore the sumptuous taste of our Veg Tikka Masala, where assorted vegetables are cooked in a creamy, spicy masala sauce, making every bite a delightful experience.</p>
          {/* <p className="menu-item-price">$9.99</p> */}
        </div>

        <div className="menu-column">
          <h5 className="menu-sub-section-name">Momo (Non-Halal)</h5>
          <h4 className="menu-item-name">CHICKEN</h4>
          <p className="menu-item-description">Savor our Chicken Momo, a beloved dumpling delight filled with minced chicken and spices, served with a tangy dipping sauce for a flavor-packed bite.</p>
          {/* <p className="menu-item-price">$13.99</p> */}
          <h4 className="menu-item-name">VEG</h4>
          <p className="menu-item-description">Enjoy our Veg Momo, tender dumplings stuffed with a finely chopped vegetable blend, steamed to perfection, and served with a flavorful dipping sauce.</p>
          {/* <p className="menu-item-price">$12.99</p> */}
        </div>
        {/* Section 1 */}
        <div className="menu-column">
          <h3 className="menu-section-name">Regular Grill</h3>
          <h4 className="menu-item-name">1/3 LB BURGER</h4>
          <p className="menu-item-description">A mouthwatering 1/3 lb burger with a handmade patty seasoned to perfection, served on a fresh bun. A classic favorite that never fails to satisfy.</p>
          {/* <p className="menu-item-price">$8.79</p> */}
        </div>

        {/* Section 2 */}
        <div className="menu-column">
          <h5 className="menu-sub-section-name">Burrito Lovers</h5>
          <h4 className="menu-item-name">BEEF BURRITO</h4>
          <p className="menu-item-description">Our beef burrito is a savory delight filled with tender beef, beans, and spices, wrapped in a warm tortilla. A satisfying meal.</p>
          {/* <p className="menu-item-price">$9.99</p> */}

          <h4 className="menu-item-name">TIKKA BOTI BURRITO (NEW RECIPE!)</h4>
          <p className="menu-item-description">Try our new Tikka Boti Burrito! It's bursting with flavor, featuring marinated pieces of succulent chicken in a tortilla wrap.</p>
          {/* <p className="menu-item-price">$9.99</p> */}
        </div>

        {/* Section 3 */}
        <div className="menu-column">
        <h5 className="menu-sub-section-name">Philly & Gyro</h5>
          <h4 className="menu-item-name">PHILLY CHEESE STEAK</h4>
          <p className="menu-item-description">Experience our Philly Cheese Steak, with thinly sliced beefsteak, melted cheese, and sautéed onions on a soft roll, creating a melt-in-your-mouth delight.</p>
          {/* <p className="menu-item-price">$11.29</p> */}

          <h3 className="menu-item-name">GYRO WITH CUCUMBER SAUCE</h3>
          <p className="menu-item-description">Our Gyro offers a delectable combination of seasoned beef, creamy cucumber sauce, and fresh veggies, wrapped in a soft pita bread for a refreshing meal.</p>
          {/* <p className="menu-item-price">$9.99</p> */}
        </div>

        <div className="menu-column">
          <h5 className="menu-sub-section-name">Sides</h5>
          <h4 className="menu-item-name">FRIES</h4>
          <p className="menu-item-description">Crispy and golden, our fries are the perfect side dish to complement your meal.</p>
          {/* <p className="menu-item-price">$3.79</p> */}

          <h4 className="menu-item-name">ONION RINGS</h4>
          <p className="menu-item-description">Enjoy our Onion Rings, lightly battered and fried to a crisp perfection, providing a delightful crunch with every bite.</p>
          {/* <p className="menu-item-price">$4.89</p> */}
        </div>

                {/* Section 10 */}
                <div className="menu-column">
          <h5 className="menu-sub-section-name">Grilled Fish</h5>
          <h4 className="menu-item-name">POMFRET WITH BONE</h4>
          <p className="menu-item-description">Delight in our Pomfret with Bone, grilled to perfection, offering a succulent and flavorful seafood option for fish lovers.</p>
          {/* <p className="menu-item-price">$16.29</p> */}

          <h4 className="menu-item-name">BASSA BONELESS</h4>
          <p className="menu-item-description">Taste the tender and flaky Bassa Boneless, lightly seasoned and grilled, providing a simple yet delicious fish dish.</p>
          {/* <p className="menu-item-price">$16.29</p> */}
          
        </div>


        {/* Section 8 */}
        <div className="menu-column">
        </div>
      </div>
    </section>
  );
};

export default Menu;
