import React from 'react';
import './AboutUs.css';
import teamImage from '../team.png'; // Update this path to where your actual logo image is stored

const AboutUs = () => {
  return (
    <section className="container">
      <h2 className="section-title">About Us</h2>
      <div className="row">
        <div className="eight columns">
          <p>
Welcome to Mom's Spice, where the legacy of traditional Indian cuisine is blended with a Bangladeshi flair. For over twenty years, Mohammod Adnan, our founder and head chef, has been at the forefront of this culinary venture, crafting dishes that celebrate the vibrant spices and flavors of his homeland. Mom's Spice is more than a restaurant—it's a celebration of heritage, community, and the unifying power of food. Each dish on our menu, steeped in the warmth of family traditions, is a tribute to Mohammod's mother and the inspiration she provided. Join us for an experience that goes beyond dining, where each meal is a journey, and every guest is treated like family. Welcome home, welcome to Mom's Spice.




</p>
          {/* ... more content about the restaurant */}
        </div>
        <div className="four columns">
        <img src={teamImage} alt="Mom's Spice team" style={{ maxHeight: '500px' }} /> {/* Adjust maxHeight as needed */}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
